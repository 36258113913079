import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import heroSectionImg from '../assets/hero-section.jpg';
import logo from '../assets/logo.png';

const navigation = [
  { name: 'Úvod', href: 'hero' },
  { name: 'Ceník', href: 'pricing' },
  { name: 'Kontakt', href: 'contact' },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="relative isolate">
      {/* Hero Section */}
      <div
        className="relative h-screen px-6 pt-14 lg:px-8"
        style={{
          backgroundImage: `url(${heroSectionImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black opacity-40" />{' '}
        {/* Optional overlay for text visibility */}
        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            aria-label="Global"
            className="flex items-center justify-between p-6 lg:px-8"
          >
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img alt="" src={logo} className="h-14 w-auto" />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href} // Use the section ID here
                  smooth={true} // Enable smooth scrolling
                  duration={500} // Duration of the scroll animation
                  className="text-lg font-semibold leading-6 text-white px-4 py-2 cursor-pointer" // No inline comment here
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
          </nav>

          <Dialog
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)} // Correct binding
            className="lg:hidden"
          >
            <div
              className="fixed inset-0 z-50"
              style={{ backgroundImage: "url('../assets/hero-section.jpg')" }}
            />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img alt="" src={logo} className="h-8 w-auto" />
                </a>
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)} // Close menu
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              {/* Menu Items */}
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href} // Use the section ID here
                        smooth={true} // Enable smooth scrolling
                        duration={500} // Duration of the scroll animation
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                        onClick={() => setMobileMenuOpen(false)} // Close menu on item click
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>
        {/* Hero Content */}
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 text-center text-white relative z-10">
          <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
            Váš spolehlivý partner pro pneumatiky
          </h1>
          <p className="mt-6 text-lg leading-8">
            Zajišťujeme kvalitní služby pneu servisu. Ať už potřebujete výměnu
            pneumatik nebo jejich opravu, jsme tu pro Vás.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer"
            >
              Kontaktujte nás
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

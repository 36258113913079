import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import React from 'react';

export default function Contact() {
  return (
    <section className="w-full bg-gradient-to-r from-gray-300 to-gray-100 py-10">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Kontaktujte nás</h2>

        {/* Contact Information Container */}
        <div className="bg-white rounded-lg shadow-md p-8">
          {/* Flexbox Layout for Contacts */}
          <div className="flex flex-col md:flex-row md:space-x-8">
            {/* Name */}
            <div className="flex items-center mb-4 md:mb-0 flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
              <UserIcon className="h-8 w-8 text-blue-500 mr-2" />
              <div>
                <h4 className="font-semibold text-lg">Jméno</h4>
                <p className="text-gray-700">František Pokorný</p>
              </div>
            </div>
            {/* Location */}
            <div className="flex items-center mb-4 md:mb-0 flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
              <MapPinIcon className="h-8 w-8 text-blue-500 mr-2" />
              <div>
                <h4 className="font-semibold text-lg">Poloha</h4>
                <p className="text-gray-700">Mírová 7, Brno 618 00</p>
              </div>
            </div>

            {/* Phone */}
            <div className="flex items-center mb-4 md:mb-0 flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
              <PhoneIcon className="h-8 w-8 text-blue-500 mr-2" />
              <div>
                <h4 className="font-semibold text-lg">Telefonní číslo</h4>
                <p className="text-gray-700">775 189 917</p>
              </div>
            </div>

            {/* Email */}
            <div className="flex items-center mb-4 md:mb-0 flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
              <EnvelopeIcon className="h-8 w-8 text-blue-500 mr-2" />
              <div>
                <h4 className="font-semibold text-lg">Email</h4>
                <p className="text-gray-700">p-o-k-y@seznam.cz</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

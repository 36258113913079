import React from 'react';

export default function Pricing() {
  return (
    <div className="flex flex-col items-center w-full bg-gradient-to-r from-gray-300 to-gray-100 py-10">
      <div className="w-full overflow-x-auto">
        {' '}
        {/* Make the container full width and enable x scrolling */}
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          {' '}
          {/* Ensure the table can expand */}
          <div className="overflow-hidden p-4 rounded-lg shadow-md bg-white">
            <table className="min-w-full text-left text-base sm:text-lg font-light">
              {' '}
              {/* Adjust font size */}
              <caption className="text-center text-lg sm:text-xl font-semibold mb-4">
                {' '}
                {/* Adjust caption size */}
                Ceník služeb
              </caption>
              <thead className="border-b font-medium text-gray-800 dark:border-neutral-500">
                <tr>
                  <th scope="col" className="px-2 sm:px-4 py-2">
                    Služba
                  </th>
                  <th scope="col" className="px-2 sm:px-4 py-2">
                    Osobní auto plechový disk
                  </th>
                  <th scope="col" className="px-2 sm:px-4 py-2">
                    Osobní auto litý disk
                  </th>
                  <th scope="col" className="px-2 sm:px-4 py-2">
                    Dodávka + kola nad 17"
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b dark:border-neutral-500 font-bold">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Kompletní přezutí vozu
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    800,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    900,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    1000,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Demontáž a montáž kola z osy vozidla
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    60,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    70,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    80,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Demontáž pneu z disku
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    55,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    60,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    65,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Montáž pneu na disk
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    30,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    35,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    40,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Vyvážení kola
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    55,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    60,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    65,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Kompletní přezutí 1 kola
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    200,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    225,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    250,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Nýt na opravu defektu
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    150,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    150,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    150,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Prýžový ventilek
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    30,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    30,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    30,- Kč
                  </td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    Kovový ventilek
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    50,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    50,- Kč
                  </td>
                  <td className="whitespace-nowrap px-2 sm:px-4 py-2 text-gray-800">
                    50,- Kč
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

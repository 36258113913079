import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import Contact from './components/Contact';
import Header from './components/Header';
import Pricing from './components/Pricing';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>Pneu servis Brno | Výměna a oprava pneumatik - Pokorný</title>
          <meta
            name="description"
            content="Profesionální pneuservis v Brně. Nabízíme opravu, výměnu a sezónní uskladnění pneumatik. Kontaktujte nás pro kvalitní služby."
          />
          <meta
            name="keywords"
            content="pneu servis Brno, oprava pneumatik, výměna pneumatik, sezónní uskladnění, pneuservis Pokorný"
          />
        </Helmet>
        <Header />
        <section id="pricing">
          <Pricing />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </Router>
    </HelmetProvider>
  );
}

export default App;
